import http from "@/utils/request";
// 根据id查询详情

export function findDetail(data) {
    return http({
        method: "get",
        url: "/distributeBoothReport/findDetail.nd",
        params:data,
    });
}

export function getExport(data) {
    return http({
        url: '/pms/pmsApi/policyQuerySettlementDetail/export.nd',
        method: 'post',
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data:data,
        responseType: 'blob'
    })
}