import { findDetail } from "@/views/boothStoreExpenses/boothStoreView/api";
import { getList } from "@/views/rewardList/api";
import Util from "@/utils/utils";
import { getExport } from "./api";

export default {
  name: "",
  data() {
    return {
      breadcrumbData: [{
        path: "/index",
        name: "index",
        title: "首页"
      },
        {
          path: "/rewardList",
          name: "rewardList",
          title: "奖励结算"
        },
        {
          path: "/rewardList/rewardView",
          name: "rewardView",
          title: "奖励明细"
        }
      ],
      pageLoadFlag: false,
      detilInfo: {},
      tableData: [],

      fileList: [],
      fileListJS: [],
      previewVisible1: false,
      previewVisible: false,
      previewImage: "",

      columns: [
        {
          label: "售达方名称",
          prop: "customerNameSp",
          width: 200
        },
        {
          label: "发票号",
          prop: "invoiceNo",
          width: 200

        },

        {
          label: "合同号",
          prop: "contractNo",
          width: 200

        },
        {
          label: "合同名称",
          prop: "contractName",
          width: 200
        },
        {
          label: "销售组织",
          prop: "salesGroupName",
          width: 200
        },
        {
          label: "主售达方名称",
          prop: "customerNameParent",
          width: 220

        },

        {
          label: "奖励名称",
          prop: "prizeName",
          width: 200

        },
        {
          label: "奖励开始时间",
          prop: "prizeBegin",
          width: 220

        },
        {
          label: "奖励结束时间",
          prop: "prizeEnd",
          width: 200

        },

        {
          label: "订单号",
          prop: "orderCode",
          width: 200

        },
        {
          label: "订单时间",
          prop: "orderDate",
          width: 200

        },
        {
          label: "发票日期",
          prop: "invoiceDate",
          width: 200

        },
        {
          label: "渠道",
          prop: "channelName",
          width: 150

        }, {
          label: "应付日期",
          prop: "copeDate",
          width: 150

        }, {
          label: "计算日期",
          prop: "dateModified",
          width: 200

        }, {
          label: "结算单状态",
          prop: "billState",
          width: 200

        }, {
          label: "结算单号",
          prop: "clearingSheetNo",
          width: 200

        }, {
          label: "结算单是否下发",
          prop: "sendFlagName",
          width: 220

        },
        {
          label: "产品名称",
          prop: "productName",
          width: 200

        }, {
          label: "系列",
          prop: "basicMtl",
          width: 200

        }, {
          label: "物料编码",
          prop: "materialCode",
          width: 200

        }, {
          label: "物料名称",
          prop: "materialDescrition",
          width: 200

        }, {
          label: "数量",
          prop: "amount",
          width: 200

        }, {
          label: "计算基数（元）",
          prop: "baseMoney",
          width: 200

        }, {
          label: "奖励率",
          prop: "prizeRate",
          width: 200

        },
        {
          label: "奖励金额",
          prop: "prizeMoney",
          width: 200

        },
        {
          label: "发票备注",
          prop: "prizeComment",
          width: 200

        }, {
          label: "计算备注",
          prop: "calcComment",
          width: 400

        }

      ],
      RouterParams: {},
      ssearchList: {
        "clearingSheetNo": "",
        "contractNo": "",
        "endTime": "",
        "invoiceNo": "",
        "pageNum": 1,
        "pageSize": 10,
        "productCodes": [],
        "salesGroups": [],
        "startTime": "",
        serviceCode: "policyQuerySettlementDetail"
      },
      tableLoading: false,
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      expLoading: false


    };
  },


  mounted() {
    if (this.$route.query) {
      this.RouterParams = this.$route.query;
      this.tablelist();
    }
  },

  methods: {
    exportClick() {
      this.ssearchList.clearingSheetNo = this.$route.query.clearingSheetNo;
      let data = JSON.parse(JSON.stringify(this.ssearchList));
      data.pageSize = 200000;
      data.pageNum = 1;
      this.expLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "奖励列表明细.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.expLoading = false;
        });
    },
    tablelist() {
      this.ssearchList.contractNo = this.$route.query.contractNo;
      let data = this.ssearchList;
      this.tableLoading = true;
      getList(data).then(res => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          if (res.data.data.records) {
            this.searchNumber = res.data.data.total;
            this.pager.count = res.data.data.total;

          }
          if (this.tableData && this.tableData.length > 0) {
            this.tableData.forEach((item, index) => {
              item.xuhao = index + 1;
              item.billState = (item.billState && item.billState == 1) ? "有效" : "作废";
              // item.proportion = (item.proportion)+'%'
            });
          }
          this.tableLoading = false;
        } else {
          this.$message.warning(res.data.message);
          this.tableLoading = false;
        }

      });
    },
    pageSearch(pager) {
      this.ssearchList.pageNum = pager.pageNo;
      this.ssearchList.pageSize = pager.pageSize;
      this.tablelist();
    },
    async handlePreview(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    async handlePreview1(file) {
      if (file.url) {
        window.open(file.url);
      }
    },
    // 查询详情
    getSignleInfo(id) {
      this.pageLoadFlag = true;
      let data = {
        id: id
      };
      findDetail(data).then(res => {
        if (res.data.code == 0) {
          this.detilInfo = res.data.data;
          // this.fileList = res.data.data.attachs;
          this.tableData = res.data.data.supplyDetailList;
          if (res.data.data.attachs && res.data.data.attachs.length > 0) {
            res.data.data.attachs.forEach(item => {
              this.fileList.push(
                {
                  uid: item.id,
                  name: item.attachName,
                  status: "done",
                  url: item.attachPath
                }
              );
            });
          }
          if (this.tableData.length > 0) {
            this.tableData.forEach(item => {
              item.fetching2 = false;
              item.fetching = false;
              item.type = item.supplyDetailCode;
              item.shopId = String(item.custShopInfoDto.id);
              item.shopName = item.custShopInfoDto.fullName;
              item.isSpecialShopText = item.custShopInfoDto.isSpecialShopText;
              item.isSpecialShop = item.custShopInfoDto.isSpecialShop;
              item.supplyTypeId = String(item.supplyType.id);
              item.isHeaderUnion = String(item.isHeaderUnion);

              item.SupplierId = item.supplierCode;
              item.SupplierName = item.supplierName;
              item.costEstimate = item.estimatedCost;
              item.completionTime = item.finishDate;
              item.saleT = item.targetNumber;
              item.salesVolumes = item.targetMoney;
              item.office = item.custShopInfoDetailDto ? item.custShopInfoDetailDto.orgName : "";
              item.officeId = item.custShopInfoDetailDto ? item.custShopInfoDetailDto.orgId : "";
              item.baseMatklId = item.custShopInfoDetailDto ? item.custShopInfoDetailDto.baseMatklId : "";
              item.baseMatklName = item.custShopInfoDetailDto ? item.custShopInfoDetailDto.baseMatklName : "";
            });
          }
          this.pageLoadFlag = false;
        } else {
          this.pageLoadFlag = false;
        }
      });
    },

    goback() {
      window.close();

    }
  }
};